<template>
  <div class="contract">
    <sideBar :topHeight="515"></sideBar>
    <div class="header">
      <Header :headSelectIndex='4'></Header>
    </div>
    <div class="scroll" ref="scroll" :style="'height:'+scrollH+'px'" @scroll="scrollFun">
      <div class="main" id="main">
        <div class="left">
          <div class="top">
            <div class="title"><img src="../assets/icon_word20.png" alt=""><span>{{document.DocName}}</span></div>
            <div class="btn" v-if="!user||(AssociatorState!='已开通'&&!isPurse)">
              <div @click="download(9.9)">9.9元 立即下载</div>
              <div @click="download(0)">0元 免费下载<div>开通会员</div>
              </div>
            </div>
            <div class="btn" v-else>
              <div class="red" @click="download(0)">0元 免费下载<div>会员价</div></div>
            </div>
          </div>
          <div class="bottom">
            <div class="content">
              <div class="head" :class="fixed?'fixed':''">{{fixed?document.DocName:''}}</div>
              <div class="preview" v-show="(AssociatorState!='已开通'&&isPurse)||AssociatorState=='已开通'">
                <iframe id="iframe-part" width="700px" height="100%"
                  :src="'https://view.officeapps.live.com/op/view.aspx?src='+document.DocUrl" frameborder="0"></iframe>
              </div>
              <img :src="document.DocPreviewPic" alt="" class="pic" @load="loadImg"
                v-show="!((AssociatorState!='已开通'&&isPurse)||AssociatorState=='已开通')">

              <div class="modal" :style="!showBtn?footP=='absolute'?'bottom:30px':'bottom:5px':'bottom:110px'"
                v-if="!((AssociatorState!='已开通'&&isPurse)||AssociatorState=='已开通')">预览结束，请下载查看全文</div>
              <div class="foot" :style="'position:'+footP">
                <div>温馨提示：合同模板仅供参考，使用前请咨询律师意见。</div>
                <div @click="goPage('ask?id=1')"><span>立即咨询</span><img src="../assets/icon_arrows_red.png" alt=""></div>
                <div class="btn" v-if="showBtn&&(!user||(AssociatorState!='已开通'&&!isPurse))">
                  <div @click="download(9.9)">9.9元 立即下载</div>
                  <div @click="download(0)">0元 免费下载<div>开通会员</div>
                  </div>
                </div>
                <div class="btn" v-if="showBtn&&((AssociatorState!='已开通'&&isPurse)||AssociatorState=='已开通')">
                  <div class="red" @click="download(0)">0元 免费下载<div>会员价</div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="../assets/pact_img_ad130.png" alt="" @click="goMember">
          <div class="recommend">
            <img src="../assets/icon_xiangguan16.png" alt="">
            <div class="list">
              <div class="item" v-for="item in list" @click="relatedRecomment(item.RecordGuid)">
                <div class="pic"><img :src="item.DocPreviewPic" alt=""></div>
                <div class="title">{{item.DocName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FootBox></FootBox>
    </div>
  </div>
</template>
<script>
  import Header from '@/components/header'
  import FootBox from '@/components/FootBox'
  import sideBar from '@/components/sideBar'
  import {
    GetContractTemplatesDetails,
    GetRelationalTemplates,
    QuickContractTemplateOrder,
    UpdateLegalDocRecord
  } from '@/api'
  export default {
    name: 'contract',
    data() {
      return {
        scrollH: 0,
        fixed: false,
        footP: 'fixed',
        showBtn: false,
        user: null,
        document: {},
        isPurse: false,
        list: [],
        isDownload: true,
        AssociatorState:''
      }
    },
    mounted() {
      this.scrollH = window.innerHeight - 150
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      if(this.user){
        this.AssociatorState=this.user.AssociatorState
      }
      console.log(this.user,this.showBtn)
      this.isMember().then(res=>{
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        if(this.user){
          this.AssociatorState=this.user.AssociatorState
        }
      })
      var MemberGuid=this.user?this.user.MemberGuid:''
        var data={
          "memberGuid": MemberGuid,
          "contractTemplateID": this.$route.query.guid
        }
        GetContractTemplatesDetails(data).then(res => {
          if (res.state == 0) {
            this.document = res.data.contractTemplate[0]
            if(MemberGuid!=''){
              this.isPurse = res.data.isPurse
            }
            var data = {
              "recordGuid": this.$route.query.guid,
              "contractType": this.document.DocColumn,
            }
            GetRelationalTemplates(data).then(res => {
              if (res.state == 0) {
                this.list = res.data
              }
            })
          } else {
            this.$message(res.des)
          }
        })
      
      _hmt.push(['_trackPageview', '/contractDetail'])
    },
    updated() {

    },
    methods: {
      download(num) {
        _hmt.push(['_trackEvent', 'click', '下载按钮', 'pc']);
        if (!this.user) {
          this.$router.push('/login')
        } else {
          if (this.user.AssociatorState != '已开通' && !this.isPurse) {
            var member = num == 9.9 ? 0 : 1
            var data = {
              "recordGuid": this.$route.query.guid,
              "memberGuid": this.user.MemberGuid,
              "orderFrom": localStorage.getItem('orderfrom'),
            }
            QuickContractTemplateOrder(data).then(res => {
              if (res.state == 0) {
                localStorage.setItem('payOrder', JSON.stringify(res.data))
                if(num == 9.9){
                  this.$router.push('/cashier')
                }else{
                  this.$router.push('/cashier?member=' + member)
                }
              } else {
                this.$message(res.des)
              }
            })

          } else {
            
            var data = {
              "memberGuid": this.user.MemberGuid,
              "contractTemplateID": this.$route.query.guid,
            }
            UpdateLegalDocRecord(data).then(res=>{
              if(res.state==0){
                var a=document.createElement('a')
                a.href=this.document.DocUrl
                a.download=this.document.DocUrl
                a.style.display="none"
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
              }else{
                this.$message(res.des)
              }
            })
          }
        }
      },
      goMember() {
        _hmt.push(['_trackEvent', 'click', '会员营销', 'pc']);
        this.$router.push('/member')
      },
      relatedRecomment(guid) {
        _hmt.push(['_trackEvent', 'click', '相关推荐-合同模板', 'pc']);
        var loading = this.$loading();
        this.$router.replace('/contractDetail?guid='+guid)
        this.$router.go(0)
      },
      scrollFun() {
        if (this.$refs.scroll.scrollTop >= 170 && this.$refs.scroll.scrollTop + this.scrollH <= document.getElementById(
            'main').clientHeight) {
          this.fixed = true
        } else {
          this.fixed = false
        }
        if (this.$refs.scroll.scrollTop + this.scrollH >= document.getElementById('main').clientHeight) {
          this.footP = 'absolute'
        } else {
          this.footP = 'fixed'
        }
        if (this.$refs.scroll.scrollTop >= 170) {
          this.showBtn = true
        } else {
          this.showBtn = false
        }
      },
      loadImg() {
        console.log(document.getElementById('main').clientHeight)
        if (document.getElementById('main').clientHeight > this.scrollH) {
          this.footP = 'fixed'
        } else {
          this.footP = 'absolute'
        }
      },
      goPage(path) {
        _hmt.push(['_trackEvent', 'click', '咨询律师按钮', 'pc']);
        if (path.indexOf('ask') >= 0 && !localStorage.getItem('user')) {
          this.$router.push('/login?to=ask&id=1')
        } else {
          this.$router.push('/' + path)
        }
      }
    },
    components: {
      Header,
      FootBox,
      sideBar
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    min-width: 940px;
    background: #21D0C8;
    height: 150px;
  }

  .scroll {
    overflow: auto;
    background: #F3F3F3;
    position: relative;

  }

  .main {
    width: 940px;
    margin: 20px auto 0;

    .left {
      width: 620px;
      margin-right: 20px;
      display: inline-block;
      vertical-align: top;

      .top {
        width: 620px;
        height: 130px;
        background: #FFFFFF;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 30px 30px 0;
        margin-bottom: 20px;

        .title {
          margin-bottom: 20px;

          img {
            vertical-align: middle;
            margin-right: 8px;
          }

          span {
            vertical-align: middle;
            font-size: 24px;
            color: #000000;
            display: inline-block;
            width: 500px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .btn {
          >div {
            padding: 6px 17px;
            border-radius: 16px;
            font-size: 16px;
            color: #FFFFFF;
            display: inline-block;
            cursor: pointer;
          }

          >div:nth-child(1) {
            background: #00C8B4;
            margin-right: 10px;
          }
          
          >div:nth-child(2) {
            background: #F24537;
            position: relative;

            div {
              width: 58px;
              height: 20px;
              background: linear-gradient(0deg, #D19961 0%, #EEBF7D 100%);
              border-radius: 10px 0px 10px 0px;
              font-size: 12px;
              font-weight: bold;
              color: #FFFFFF;
              text-align: center;
              line-height: 20px;
              position: absolute;
              top: -11px;
              right: -42px;
            }
          }
        }
      }
      .red{
            background: #F24537!important;
            position: relative;
            div{
              padding: 0px 5px!important;
              background: linear-gradient(0deg, #D19961 0%, #EEBF7D 100%);
border-radius: 10px 0px 10px 0px;font-size: 12px;font-weight: bold;color: #FFFFFF;
position: absolute;top: -5px;
              right: -31px;line-height: 18px;
            }
          }
      .bottom {
        .content {
          width: 620px;
          position: relative;
          padding: 40px 0 30px;
          background: #fff;
          border-radius: 20px 20px 0px 0px;
          overflow: hidden;

          .head {
            width: 620px;
            height: 40px;
            background: #FCFCFC;
            border-radius: 20px 20px 0px 0px;
            position: absolute;
            top: 0;
            z-index: 9;
            line-height: 40px;
            padding-left: 30px;
            font-size: 16px;
            color: #000000;
            box-sizing: border-box;
          }

          .head.fixed {
            position: fixed;
            top: 150px;
            border-radius: 0;
          }

          .preview {
            position: relative;
            top: -100px;
            left: 0;
            width: 620px;
            height: 1000px;
            overflow: hidden;

            iframe {
              border-radius: 50px 50px 0 0;
              position: relative;
              left: -12px;
            }
          }

          .pic {
            width: 620px;
            display: block;
          }

          .foot {
            position: fixed;
            bottom: 0;
            width: 620px;
            background: #FAE3C8;
            line-height: 30px;
            z-index: 4;

            >div:nth-child(1) {
              font-size: 12px;
              color: #604322;
              padding-left: 30px;
            }

            >div:nth-child(2) {
              font-size: 13px;
              color: #F24537;
              position: absolute;
              right: 30px;
              top: 0;
              cursor: pointer;

              img {
                vertical-align: middle;
                margin-left: 9px;
              }

              span {
                vertical-align: middle;
              }
            }

            .btn {
              background: #fff;
              text-align: center;
              padding: 22px 0 8px;

              >div {
                padding: 10px 48px;
                border-radius: 25px;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: bold;
                display: inline-block;
                cursor: pointer;
              }

              >div:nth-child(1) {
                background: #00C8B4;
                margin-right: 10px;
              }

              >div:nth-child(2) {
                background: #F24537;
                position: relative;

                div {
                  width: 66px;
                  height: 24px;
                  background: linear-gradient(0deg, #D19961 0%, #EEBF7D 100%);
                  border-radius: 10px 0px 10px 0px;
                  font-size: 14px;
                  font-weight: bold;
                  color: #FFFFFF;
                  text-align: center;
                  line-height: 24px;
                  position: absolute;
                  top: -13px;
                  right: -10px;
                }
              }
            }
            .red{
              div{
                padding: 2px 6px!important;
                font-size: 14px;
                position: absolute;top: -10px;
                right: -21px;line-height: 16px;
              }
            }
          }

          .modal {
            width: 620px;
            height: 80px;
            background-image: url('../assets/img_mask80.png');
            position: absolute;
            bottom: 110px;
            text-align: center;
            font-size: 16px;
            color: #00C8B4;
            line-height: 100px;
            z-index: 1;
          }
        }
      }
    }

    .right {
      width: 300px;
      display: inline-block;
      margin-bottom: 20px;

      >img {
        margin-bottom: 20px;
      }

      .recommend {
        width: 300px;
        background: #FFFFFF;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 20px;

        >img {
          margin-bottom: 14px;
        }

        .list {
          .item {
            margin-right: 10px;
            display: inline-block;
            margin-bottom: 20px;
            width: 125px;

            .pic {
              width: 125px;
              height: 177px;
              background: #FFFFFF;
              border: 1px solid #D2D2D2;
              border-radius: 10px;
              overflow: hidden;

              img {
                width: 125px;
                height: 177px;
              }
            }

            .title {
              font-size: 14px;
              color: #333333;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-top: 10px;
            }
          }

          .item:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }

</style>
<style lang="scss">


</style>
